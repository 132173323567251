import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { Provider as AlertProvider, transitions } from 'react-alert'
import { TimelineMax, TweenMax, TweenLite } from 'gsap'
import { Page, Modals } from '@components'
import { Alert } from '@elements'
import client from './gatsby-apollo'
import '@styles/app.scss'
import ScrollMagic from 'scrollmagic'

// eslint-disable-next-line react/display-name
export const wrapPageElement = ({ element, props }) => {
  // All routing logic is in the Page component
  return (
    <>
      {/* <Header /> */}
      <Page {...props}>{React.createElement(element.type, props)}</Page>
      {/* <Footer /> */}
    </>
  )
}

export const wrapRootElement = ({ element }) => {
  const alertOptions = {
    position: 'top right',
    timeout: 2000,
    offset: '25px 30px -10px',
    transition: transitions.SCALE
  }

  return (
    <ApolloProvider client={client}>
      <AlertProvider template={Alert} {...alertOptions}>
        <Modals />
        {element}
        {/* GSAP TweenMax for ScrollMagic */}
        {typeof window !== 'undefined' && (
          <script>
            {(function (root, factory) {
              if (typeof define === 'function' && define.amd) {
                // AMD. Register as an anonymous module.
                define(['ScrollMagic', 'TweenMax', 'TimelineMax'], factory)
              } else if (typeof exports === 'object') {
                // CommonJS
                // Loads whole gsap package onto global scope.
                require('gsap')
                factory(require('scrollmagic'), TweenMax, TimelineMax)
              } else {
                // Browser globals
                factory(ScrollMagic, TweenMax, TimelineMax)
              }
            })(this, function (ScrollMagic, Tween, Timeline) {
              'use strict'
              const NAMESPACE = 'animation.gsap'

              // (BUILD) - REMOVE IN MINIFY - START
              const console = window.console || {},
                err = Function.prototype.bind.call(console.error || console.log || function () {}, console)
              if (!ScrollMagic) {
                err(
                  '(' +
                    NAMESPACE +
                    ") -> ERROR: The ScrollMagic main module could not be found. Please make sure it's loaded before this plugin or use an asynchronous loader like requirejs."
                )
              }
              if (!Tween) {
                err(
                  '(' +
                    NAMESPACE +
                    ') -> ERROR: TweenLite or TweenMax could not be found. Please make sure GSAP is loaded before ScrollMagic or use an asynchronous loader like requirejs.'
                )
              }

              ScrollMagic.Scene.addOption(
                'tweenChanges', // name
                false, // default
                function (val) {
                  // validation callback
                  return !!val
                }
              )
              // extend scene
              ScrollMagic.Scene.extend(function () {
                let Scene = this,
                  _tween

                // (BUILD) - REMOVE IN MINIFY - START
                const log = function () {
                  if (Scene._log) {
                    // not available, when main source minified
                    Array.prototype.splice.call(arguments, 1, 0, '(' + NAMESPACE + ')', '->')
                    Scene._log.apply(this, arguments)
                  }
                }
                // (BUILD) - REMOVE IN MINIFY - END

                // set listeners
                Scene.on('progress.plugin_gsap', function () {
                  updateTweenProgress()
                })
                Scene.on('destroy.plugin_gsap', function (e) {
                  Scene.removeTween(e.reset)
                })

                var updateTweenProgress = function () {
                  if (_tween) {
                    const progress = Scene.progress(),
                      state = Scene.state()
                    if (_tween.repeat && _tween.repeat() === -1) {
                      // infinite loop, so not in relation to progress
                      if (state === 'DURING' && _tween.paused()) {
                        _tween.play()
                      } else if (state !== 'DURING' && !_tween.paused()) {
                        _tween.pause()
                      }
                    } else if (progress != _tween.progress()) {
                      // do we even need to update the progress?
                      // no infinite loop - so should we just play or go to a specific point in time?
                      if (Scene.duration() === 0) {
                        // play the animation
                        if (progress > 0) {
                          // play from 0 to 1
                          _tween.play()
                        } else {
                          // play from 1 to 0
                          _tween.reverse()
                        }
                      } else {
                        // go to a specific point in time
                        if (Scene.tweenChanges() && _tween.tweenTo) {
                          // go smooth
                          _tween.tweenTo(progress * _tween.duration())
                        } else {
                          // just hard set it
                          _tween.progress(progress).pause()
                        }
                      }
                    }
                  }
                }

                Scene.setTween = function (TweenObject, duration, params) {
                  let newTween
                  if (arguments.length > 1) {
                    if (arguments.length < 3) {
                      params = duration
                      duration = 1
                    }
                    TweenObject = Tween.to(TweenObject, duration, params)
                  }
                  try {
                    // wrap Tween into a Timeline Object if available to include delay and repeats in the duration and standardize methods.
                    if (Timeline) {
                      newTween = new Timeline({ smoothChildTiming: true }).add(TweenObject)
                    } else {
                      newTween = TweenObject
                    }
                    newTween.pause()
                  } catch (e) {
                    log(1, "ERROR calling method 'setTween()': Supplied argument is not a valid TweenObject")
                    return Scene
                  }
                  if (_tween) {
                    // kill old tween?
                    Scene.removeTween()
                  }
                  _tween = newTween

                  // some properties need to be transferred it to the wrapper, otherwise they would get lost.
                  if (TweenObject.repeat && TweenObject.repeat() === -1) {
                    // TweenMax or TimelineMax Object?
                    _tween.repeat(-1)
                    _tween.yoyo(TweenObject.yoyo())
                  }
                  // (BUILD) - REMOVE IN MINIFY - START
                  // Some tween validations and debugging helpers

                  if (Scene.tweenChanges() && !_tween.tweenTo) {
                    log(
                      2,
                      'WARNING: tweenChanges will only work if the TimelineMax object is available for ScrollMagic.'
                    )
                  }

                  // check if there are position tweens defined for the trigger and warn about it :)
                  if (_tween && Scene.controller() && Scene.triggerElement() && Scene.loglevel() >= 2) {
                    // controller is needed to know scroll direction.
                    const triggerTweens = Tween.getTweensOf(Scene.triggerElement()),
                      vertical = Scene.controller().info('vertical')
                    triggerTweens.forEach(function (value, index) {
                      const tweenvars = value.vars.css || value.vars,
                        condition = vertical
                          ? tweenvars.top !== undefined || tweenvars.bottom !== undefined
                          : tweenvars.left !== undefined || tweenvars.right !== undefined
                      if (condition) {
                        log(
                          2,
                          'WARNING: Tweening the position of the trigger element affects the scene timing and should be avoided!'
                        )
                        return false
                      }
                    })
                  }

                  // warn about tween overwrites, when an element is tweened multiple times
                  if (parseFloat(TweenLite.version) >= 1.14) {
                    // onOverwrite only present since GSAP v1.14.0
                    const list = _tween.getChildren ? _tween.getChildren(true, true, false) : [_tween], // get all nested tween objects
                      newCallback = function () {
                        log(
                          2,
                          'WARNING: tween was overwritten by another. To learn how to avoid this issue see here: https://github.com/janpaepke/ScrollMagic/wiki/WARNING:-tween-was-overwritten-by-another'
                        )
                      }
                    for (var i = 0, thisTween, oldCallback; i < list.length; i++) {
                      /*jshint loopfunc: true */
                      thisTween = list[i]
                      if (oldCallback !== newCallback) {
                        // if tweens is added more than once
                        oldCallback = thisTween.vars.onOverwrite
                        thisTween.vars.onOverwrite = function () {
                          if (oldCallback) {
                            oldCallback.apply(this, arguments)
                          }
                          newCallback.apply(this, arguments)
                        }
                      }
                    }
                  }
                  // (BUILD) - REMOVE IN MINIFY - END
                  log(3, 'added tween')

                  updateTweenProgress()
                  return Scene
                }

                /**
                 * Remove the tween from the scene.
                 * This will terminate the control of the Scene over the tween.
                 *
                 * Using the reset option you can decide if the tween should remain in the current state or be rewound to set the target elements back to the state they were in before the tween was added to the scene.
                 * @memberof! animation.GSAP#
                 *
                 * @example
                 * // remove the tween from the scene without resetting it
                 * scene.removeTween();
                 *
                 * // remove the tween from the scene and reset it to initial position
                 * scene.removeTween(true);
                 *
                 * @param {boolean} [reset=false] - If `true` the tween will be reset to its initial values.
                 * @returns {Scene} Parent object for chaining.
                 */
                Scene.removeTween = function (reset) {
                  if (_tween) {
                    if (reset) {
                      _tween.progress(0).pause()
                    }
                    _tween.kill()
                    _tween = undefined
                    log(3, 'removed tween (reset: ' + (reset ? 'true' : 'false') + ')')
                  }
                  return Scene
                }
              })
            })}
          </script>
        )}
      </AlertProvider>
    </ApolloProvider>
  )
}
