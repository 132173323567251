// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-detail-tsx": () => import("./../../../src/pages/detail/*.tsx" /* webpackChunkName: "component---src-pages-detail-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-[id]-index-tsx": () => import("./../../../src/pages/news/[id]/index.tsx" /* webpackChunkName: "component---src-pages-news-[id]-index-tsx" */),
  "component---src-pages-news-coverage-index-tsx": () => import("./../../../src/pages/news/coverage/index.tsx" /* webpackChunkName: "component---src-pages-news-coverage-index-tsx" */),
  "component---src-pages-news-coverage-render-black-column-tsx": () => import("./../../../src/pages/news/coverage/RenderBlackColumn.tsx" /* webpackChunkName: "component---src-pages-news-coverage-render-black-column-tsx" */),
  "component---src-pages-news-coverage-render-gray-small-column-tsx": () => import("./../../../src/pages/news/coverage/RenderGraySmallColumn.tsx" /* webpackChunkName: "component---src-pages-news-coverage-render-gray-small-column-tsx" */),
  "component---src-pages-news-coverage-render-navigation-tsx": () => import("./../../../src/pages/news/coverage/RenderNavigation.tsx" /* webpackChunkName: "component---src-pages-news-coverage-render-navigation-tsx" */),
  "component---src-pages-news-coverage-render-purple-column-tsx": () => import("./../../../src/pages/news/coverage/RenderPurpleColumn.tsx" /* webpackChunkName: "component---src-pages-news-coverage-render-purple-column-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-media-index-tsx": () => import("./../../../src/pages/news/media/index.tsx" /* webpackChunkName: "component---src-pages-news-media-index-tsx" */),
  "component---src-pages-news-media-listen-index-tsx": () => import("./../../../src/pages/news/media/listen/index.tsx" /* webpackChunkName: "component---src-pages-news-media-listen-index-tsx" */),
  "component---src-pages-news-media-read-index-tsx": () => import("./../../../src/pages/news/media/read/index.tsx" /* webpackChunkName: "component---src-pages-news-media-read-index-tsx" */),
  "component---src-pages-news-media-watch-index-tsx": () => import("./../../../src/pages/news/media/watch/index.tsx" /* webpackChunkName: "component---src-pages-news-media-watch-index-tsx" */),
  "component---src-pages-news-news-list-index-tsx": () => import("./../../../src/pages/news/news-list/index.tsx" /* webpackChunkName: "component---src-pages-news-news-list-index-tsx" */),
  "component---src-pages-news-research-index-tsx": () => import("./../../../src/pages/news/research/index.tsx" /* webpackChunkName: "component---src-pages-news-research-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

