import loadable from '@loadable/component'

export const Page = loadable(() => import('./Page/Page'))
// export const Container = loadable(() => import('./Container/Container'))
export const Header = loadable(() => import('./Header/Header'))
export const Footer = loadable(() => import('./Footer/Footer'))
export const Hero = loadable(() => import('./Hero/Hero'))
export const NewsHero = loadable(() => import('./NewsHero/NewsHero'))
export const NewsSlider = loadable(() => import('./NewsSlider/NewsSlider'))
export const Menu = loadable(() => import('./Menu/Menu'))
export const Why = loadable(() => import('./Why/Why'))
export const MediaScrollButtons = loadable(() => import('./MediaScrollButtons/MediaScrollButtons'))
export const MentalHealthConditions = loadable(() => import('./MentalHealthConditions/MentalHealthConditions'))
export const WhereWeInvest = loadable(() => import('./WhereWeInvest/WhereWeInvest'))
export const PortfolioList = loadable(() => import('./PortfolioList/PortfolioList'))
export const PortfolioReviews = loadable(() => import('./PortfolioReviews/PortfolioReviews'))
export const ResourcesWideCard = loadable(() => import('./ResourcesWideCard/ResourcesWideCard'))
export const ResourcesSmallCard = loadable(() => import('./ResourcesSmallCard/ResourcesSmallCard'))
export const AboutSlider = loadable(() => import('./AboutSlider/AboutSlider'))
export const HomePortfolio = loadable(() => import('./HomePortfolio/HomePortfolio'))
export const PortfolioDetailNavigation = loadable(() => import('./PortfolioDetailNavigation/PortfolioDetailNavigation'))
export const NewsTabsNavigation = loadable(() => import('./NewsTabsNavigation/NewsTabsNavigation'))
export const PortfolioDetailFeaturedNews = loadable(
  () => import('./PortfolioDetailFeaturedNews/PortfolioDetailFeaturedNews')
)
export const Reviews = loadable(() => import('./Reviews/Reviews'))
export const MeetTheTeam = loadable(() => import('./MeetTheTeam/MeetTheTeam'))
export const TeamMember = loadable(() => import('./TeamMember/TeamMember'))
export const CauseWeSupport = loadable(() => import('./CauseWeSupport/CauseWeSupport'))
export const ExtendedFamily = loadable(() => import('./ExtendedFamily/ExtendedFamily'))
export const NewsLayout = loadable(() => import('../layouts/NewsLayout'))

export const Calendar = loadable(() => import('./Calendar/Calendar'))

export const SEO = loadable(() => import('./SEO'))
export const ErrorBoundary = loadable(() => import('./ErrorBoundary/ErrorBoundary'))

export const Modals = loadable(() => import('./Modals/Modals'))

export { default as Select } from './Select/Select'
export { default as Autocomplete } from './Autocomplete/Autocomplete'
export { default as Modal } from './Modal/Modal'

export const Form = loadable(() => import('./Form/Form'))

export type { SelectState, SelectProps } from './Select/Select'
